export default function CompanyInfo({}) {
  return (
    <>
      <div className="border rounded-s border-amber-300 p-5 mx-auto">
        <div className="border-b-2 border-amber-300 p-2 mb-2">
          <h1 className="text-center text-amber-300">MONOTOGEL</h1>
        </div>
        <div>
          <h1 className="text-center text-amber-300">
            DAFTAR SITUS TOGEL ONLINE RESMI TARUHAN 100 PERAK HADIAH 4D 10JT
            TERPERCAYA DI INDONESIA
          </h1>
          <br />
          <p className="text-amber-300">
            Halo Pecinta Togel Online, selamat datang dalam pembahasan seputar
            Situs Togel Resmi Toto Macau & Hongkong Pools Hadiah Terbesar dan
            Terlengkap Di Asia. Apakah anda sudah memahami apa yang disebut
            dengan Situs Togel Resmi ? Jika belum, maka kami akan memberikan
            sedikit informasi mengenai hal ini sebelum kita masuk lebih jauh
            dalam pembahasan kali ini. Situs Togel Resmi adalah sebuah istilah
            dalam permainan Situs Togel online yang diberikan kepada sebuah Toto
            Macau Terbaik yang sudah memiliki Lisensi Perjudian Internasional
            dari berbagai Lembaga Judi yang yang menaungi kegiatan perjudian
            Online di Berbagai Negara. Perlu anda ketahui bahwa tidak semua Toto
            Macau di Indonesia sudah memiliki Lisensi Resmi Perjudian online
            ini. MONOTOGEL merupakan salah satu Toto Macau Terpercaya dan
            Terlengkap Di Asia yang sudah memiliki Lisensi Perjudian
            Internasional ini. Tidak tanggung tanggung, bahkan sekarang
            MONOTOGEL sudah mengantongi 4 Lisensi Resmi sekaligus seperti
            Lisensi Resmi Pagcor, Lisensi Resmi WLA, Lisensi Resmi NYX Gaming,
            dan Lisensi Resmi BMM Testlabs. Selain hadir sebagai Situs Togel
            Resmi, MONOTOGEL juga hadir sebagai Situs Togel Resmi Toto Macau &
            Hongkong Pools yang menyediakan Bandar Togel Hadiah Terbesar di
            Asia. Tidak hanya itu, kami juga sudah menyediakan permainan Togel
            Online terlengkap seperti pasaran Togel Online terlengkap dan varian
            permainan judi Togel Online Terlengkap.
          </p>
        </div>
        <br />
        <div>
          <p className="text-amber-300">
            Togel Toto Macau merupakan salah satu pasaran Togel Online paling
            populer di Asia, termasuk di Indonesia. Bandar Togel Toto Macau juga
            sudah sejak lama hadir di Indonesia dan tidak heran jika pasaran
            Togel Online yang satu ini sudah memiliki banyak sekali peminat dari
            berbagai pecinta Togel Online di di Indonesia. Salah satu keuntungan
            ketika anda melakukan permainan Togel Toto Macau adalah anda bisa
            mendapatkan hadiah kemenangan togel terbesar 10 Juta Rupiah apabila
            anda berhasil menebak angka keluaran 4D pada pasaran Togel Online
            Toto Macau. Apakah anda sudah mengetahui bagaimana cara memenangkan
            hadiah togel terbesar ini ?
          </p>
        </div>
        <br />
        <div>
          <p className="text-amber-300">
            Untuk dapat memenangkan hadiah kemenangan togel terbesar ini ada
            beberapa hal yang harus anda perhatikan, seperti besaran biaya
            taruhan yang anda gunakan, mode betting atau mode taruhan, dan juga
            jenis permainan togel yang anda mainkan saat melakukan pemasangan
            angka jitu pada Bandar Togel Toto Macau Hadiah 4D 10 Juta. Biaya
            taruhan yang anda perlukan untuk bisa memenangkan hadiah togel 4D 10
            Juta Rupiah pada Bandar Togel Toto Macau adalah 1000 Rupiah saja.
            Anda juga harus memperhatikan mode taruhan yang anda gunakan, jika
            ingin mendapatkan hadiah terbesar togel 4D maka anda harus melakukan
            pemasangan angka jitu dengan mode Betting Full. Mode Betting Full
            sendiri merupakan salah satu mode taruhan yang sudah tersedia di
            Situs Togel Resmi Macau Pools dan tentu bisa anda gunakan pada
            pasaran Togel Online manapun, termasuk pada pasaran Togel Online
            Toto Macau. Setelah menentukan nilai taruhan dan mode bertaruh, maka
            anda juga harus memperhatikan jenis permainan togel yang anda
            mainkan. Karena hadiah togel terbesar 10 Juta Rupiah dengan modal
            1000 Rupiah saja hanya bisa anda dapatkan dalam jenis permainan
            Togel Online 4D atau 4 Angka.
          </p>
        </div>
        <br />
        <div>
          <p className="text-amber-300">
            Macau Pools yang menaungi langsung Bandar Togel Online Toto Macau
            menetapkan pengundian Results terbanyak setiap harinya yaitu
            sebanyak 4 kali pengundian Results. Hal ini tentu sangat menarik
            karena Toto Macau menjadi satu satunya Pasaran Togel Online yang
            mengadakan pengundian results sebanyak 4 kali dalam satu hari. Anda
            juga bisa menyaksikan secara langsung pengundian Results dari Togel
            Online Toto Macau untuk data keluaran Toto Macau Tercepat setiap
            hari, karena Situs Togel Resmi Toto Macau selalu menyiarkan secara
            Live pengundian results yang mereka lakukan melalui live streaming
            di Channel Youtube resmi mereka. Jika anda ingin ikut menyaksikan
            pengundian results Toto Macau, maka anda bisa mengunjungi tayangan
            Live Streaming Youtube Bandar Tototo Macau Online Hadiah 4D 10 Juta.
          </p>
        </div>
      </div>
    </>
  );
}
