export default function AdsBanner({ image_uri, target_uri }) {
  return (
    <>
      <a href={target_uri}>
        <div className="glitch">
          <img src={image_uri} className="w-full" alt="" />
          <div className="glitch__layers">
            <div
              style={{ backgroundImage: `url(${image_uri})` }}
              className="glitch__layer bg-cover w-full"
            ></div>
            <div
              style={{ backgroundImage: `url(${image_uri})` }}
              className="glitch__layer bg-cover w-full"
            ></div>
            <div
              style={{ backgroundImage: `url(${image_uri})` }}
              className="glitch__layer bg-cover w-full"
            ></div>
          </div>
        </div>
      </a>
    </>
  );
}
