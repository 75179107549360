import AdsBanner from "./AdsBanner";

export default function BannersArray({ banners }) {
  return (
    <>
      <div className="grid grid-cols-2 gap-5 mb-5">
        {/* <div className="flex flex-col justify-center content-center items-center gap-5 my-5"> */}
        {banners.map((banner, i) => (
          <div className="block w-full" key={`b-${i}`}>
            <AdsBanner
              image_uri={banner.image_uri}
              target_uri={banner.target_uri}
            />
          </div>
        ))}
      </div>
    </>
  );
}
