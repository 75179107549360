import BannersArray from "@/Components/BannersArray";
import CompanyInfo from "@/Components/CompanyInfo";
import CredentialArea from "@/Components/CredentialArea";
import MainHeader from "@/Components/MainHeader";
import NewsMarquee from "@/Components/NewsMarquee";
import { RegForm } from "@/Components/RegisterForm";
import { useEffect } from "react";

export default function Landing({}) {
  const landingNewsData = [
    "SELAMAT DATANG DI MONOTOGEL - AGEN TOGEL ONLINE TERBAIK & TERPOPULER MASA KINI - SELAMAT BERGABUNG & SEMOGA BERUNTUNG.",
    "MINIMAL DEPOSIT HANYA RP 10.000,- DAN MINIMAL WITHDRAW RP 50.000,- ** TERIMA KASIH **",
  ];

  const bannersData = [
    {
      image_uri: "/img/Banner-4.jpeg",
      target_uri: "#",
    },
    {
      image_uri: "/img/Banner-5.jpeg",
      target_uri: "#",
    },
  ];

  // useEffect(() => {
  //   const header = document.querySelector("#header");
  //   const mainHeader = document.querySelector("#main-header");
  //   const logo = document.querySelector("#logo");
  //   window.addEventListener("scroll", () => {
  //     if (window.scrollY > 64) {
  //       header.classList.add("-translate-y-4");
  //       logo.classList.remove("scale-125");

  //       mainHeader.classList.add("scale-y-75");
  //       mainHeader.classList.add("shadow-xl");
  //       return;
  //     }

  //     header.classList.remove("-translate-y-4");
  //     logo.classList.add("scale-125");

  //     mainHeader.classList.remove("scale-y-75");
  //     mainHeader.classList.remove("shadow-xl");
  //   });
  // }, []);

  return (
    <>
      <div className=" bg-[url('/img/bg-main.jpeg')] bg-cover bg-no-repeat bg-fixed">
        <div className="container mx-auto px-4 pt-2">
          {/* <MainHeader /> */}
          {/* <header */}
          <BannersArray banners={bannersData} />
          <NewsMarquee newsData={landingNewsData} />
          {/* <CredentialArea /> */}
          <RegForm />
          <CompanyInfo />
          <footer className="bg-neutral-100 text-center text-neutral-600 dark:bg-neutral-600 dark:text-neutral-200 lg:text-left">
            <div className="bg-neutral-200 p-6 text-center dark:bg-neutral-700">
              <span>© 2018-2023 Copyright:</span>
              <a
                className="font-semibold text-neutral-600 dark:text-neutral-400"
                href="https://monotogel.com/"
              >
                MONOTOGEL.com. All Right Reserved.
              </a>
            </div>
          </footer>
          {/* <div className="h-screen bg-blue-100"></div>
          <div className="h-screen bg-red-100"></div>
          <div className="h-screen bg-green-100"></div> */}
        </div>
      </div>
    </>
  );
}
