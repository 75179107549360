export default function NewsMarquee({ newsData }) {
  return (
    <>
      <section className="flex whitespace-no-wrap overflow-x-hidden w-full bg-amber-300 rounded-s">
        <div className="relative">
          <ul className="flex motion-safe:animate-marquee w-full">
            {newsData.map((x, i) => (
              <li className="mx-1 my-2 flex-grow" key={`p1-${i}`}>
                <span className="whitespace-nowrap text-red-600">{x}</span>
              </li>
            ))}
          </ul>
          <ul className="flex absolute top-0 motion-safe:animate-marquee2 w-full">
            {newsData.map((x, i) => (
              <li className="mx-1 my-2 flex-grow" key={`p1-${i}`}>
                <span className="whitespace-nowrap text-red-600">{x}</span>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
}
