import {
  useState,
  useEffect,
  useRef,
  createRef,
  forwardRef,
  useCallback,
} from "react";
import Swal from "sweetalert2";
import { Button, Dropdown, Label, TextInput } from "flowbite-react";

const RegForm = () => {
  const registrationFormData = useRef();
  const [isLoading, setIsLoading] = useState();
  const [regAlert, setRegAlert] = useState({
    show: false,
    message: "",
  });

  const resetRegistrationForm = () => {
    if (!registrationFormData.current) return;
    registrationFormData.current.reset();
    setRegAlert({
      show: false,
      message: "",
    });
  };

  const handleRegistrationSubmit = useCallback(async () => {
    const regData = new FormData(registrationFormData.current);
    setIsLoading(true);
    const regResp = await fetch(
      "https://indotogel999.com/member/register_member",
      {
        method: "POST",
        body: regData,
        headers: {
          //   "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*",
        },
      }
    ).catch((error) => {
      console.log(error);
      setIsLoading(false);
      return;
    });

    const respText = await regResp.text();
    if (respText !== "SUCCESS") {
      setRegAlert({
        show: true,
        message: respText,
      });
      window.scrollTo(0, 0);
    } else {
      resetRegistrationForm();
      // setIsOpen(false);
      Swal.fire({
        icon: "success",
        title: "PENDAFTARAN",
        text: "Pendaftaran Akun Berhasil",
        showConfirmButton: true,
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (
        document.activeElement.type === "number" &&
        document.activeElement.classList.contains("noscroll")
      ) {
        document.activeElement.blur();
      }
    });

    document.addEventListener("keydown", function (event) {
      if (
        document.activeElement.type === "number" &&
        document.activeElement.classList.contains("noscroll")
      ) {
        if (["ArrowUp", "ArrowDown"].includes(event.code)) {
          event.preventDefault();
        }
      }
    });
  }, []);

  return (
    <>
      <div className="bg-black/70 p-3 w-full relative">
        <h1 className="text-center text-red-200">PENDAFTARAN MEMBER VIP</h1>
        <hr />
        {isLoading ? (
          <div class="absolute bg-black/70 z-10 h-full w-full flex items-center justify-center -m-3">
            <div class="flex items-center">
              <span class="text-3xl mr-4">Loading</span>
              <svg
                class="animate-spin h-8 w-8 text-gray-800"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </div>
        ) : null}
        {regAlert.show ? (
          <>
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">ERROR!</strong>
              <span className="block sm:inline"> {regAlert.message}</span>
              <span
                className="absolute top-0 bottom-0 right-0 px-4 py-3"
                onClick={() => setRegAlert({ ...regAlert, show: false })}
              >
                <svg
                  className="fill-current h-6 w-6 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          </>
        ) : null}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleRegistrationSubmit();
          }}
          className="flex flex-col gap-4"
          ref={registrationFormData}
        >
          <div>
            <input
              id="web"
              name="web"
              type="hidden"
              required={true}
              value="anothertogel.com"
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                className=" text-red-200"
                htmlFor="username"
                value="Username Login"
              />
            </div>
            <TextInput
              id="username"
              name="username"
              type="text"
              required={true}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                className=" text-red-200"
                htmlFor="password"
                value="Password"
              />
            </div>
            <TextInput
              id="password"
              name="password"
              type="password"
              required={true}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                className=" text-red-200"
                htmlFor="vpassword"
                value="Konfirmasi Password"
              />
            </div>
            <TextInput
              id="vpassword"
              name="vpassword"
              type="password"
              required={true}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                className=" text-red-200"
                htmlFor="fullname"
                value="Nama Lengkap"
              />
            </div>
            <TextInput
              id="fullname"
              name="fullname"
              type="text"
              required={true}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label className=" text-red-200" htmlFor="email" value="Email" />
            </div>
            <TextInput id="email" name="email" type="email" required={true} />
          </div>
          <div>
            <label
              htmlFor="contact_number"
              className="block mb-2 text-sm font-medium text-red-200"
            >
              Nomor Ponsel
            </label>
            <input
              type="number"
              id="contact_number"
              name="contact_number"
              className="noscroll bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="bank"
              className="block mb-2 text-sm font-medium text-red-200"
            >
              Bank Terdaftar
            </label>
            <select
              id="bank"
              name="bank"
              defaultValue={null}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option>Pilih Salah Satu Bank</option>
              <option value="BCA">BCA</option>
              <option value="BRI">BRI</option>
              <option value="BNI">BNI</option>
              <option value="MANDIRI">Mandiri</option>
              <option value="BSI">BSI</option>
              <option value="GOPAY">GOPAY</option>
              <option value="OVO">OVO</option>
              <option value="SHOPEE PAY">SHOPEE PAY</option>
              <option value="DANA">DANA</option>
              <option value="LINK AJA">LINK AJA</option>
            </select>
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                className=" text-red-200"
                htmlFor="account_name"
                value="Nama Rekening"
              />
            </div>
            <TextInput
              id="account_name"
              name="account_name"
              type="text"
              helperText="Harus sama dengan nama yang tertera di rekening bank."
              required={true}
            />
          </div>
          <div>
            <label
              htmlFor="account_number"
              className="block mb-2 text-sm font-medium text-red-200"
            >
              Nomor Rekening
            </label>
            <input
              type="number"
              id="account_number"
              name="account_number"
              className="noscroll bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="personal_question"
              className="block mb-2 text-sm font-medium text-red-200"
            >
              Pertanyaan Rahasia
            </label>
            <select
              id="personal_question"
              name="personal_question"
              defaultValue={null}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option>Pilih Salah Satu Pertanyaan</option>
              <option value="Tempat Kelahiran Andar">
                Tempat Kelahiran Anda?
              </option>
              <option value="Nama Ibu Kandung?">Nama Ibu Kandung?</option>
              <option value="Nama pacr/istri yang masih dicintai?">
                Nama pacar/istri yang masih dicintai?
              </option>
              <option value="Nama binatang peliharaan pertama?">
                Nama binatang peliharaan pertama?
              </option>
              <option value="Nama sekolah pertama?">
                Nama sekolah pertama?
              </option>
            </select>
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                className=" text-red-200"
                htmlFor="jawaban"
                value="Jawaban"
              />
            </div>
            <TextInput
              id="jawaban"
              name="jawaban"
              type="text"
              required={true}
            />
          </div>
          <Button type="submit">Daftar</Button>
        </form>
      </div>
    </>
  );
};

const useRegistrationForm = () => {
  const registrationFormData = useRef();
  const [regAlert, setRegAlert] = useState({
    show: false,
    message: "",
  });

  const resetRegistrationForm = () => {
    if (!registrationFormData.current) return;
    registrationFormData.current.reset();
    setRegAlert({
      show: false,
      message: "",
    });
  };

  const handleRegistrationSubmit = useCallback(async () => {
    const regData = new FormData(registrationFormData.current);
    const regResp = await fetch(
      "https://indotogel999.com/member/register_member",
      {
        method: "POST",
        body: regData,
        headers: {
          //   "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*",
        },
      }
    ).catch((error) => {
      console.log(error);
      return;
    });

    const respText = await regResp.text();
    if (respText !== "SUCCESS") {
      setRegAlert({
        show: true,
        message: respText,
      });
    } else {
      resetRegistrationForm();
      setIsOpen(false);
      Swal.fire({
        icon: "success",
        title: "PENDAFTARAN",
        text: "Pendaftaran Akun Berhasil",
        showConfirmButton: true,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (
        document.activeElement.type === "number" &&
        document.activeElement.classList.contains("noscroll")
      ) {
        document.activeElement.blur();
      }
    });

    document.addEventListener("keydown", function (event) {
      if (
        document.activeElement.type === "number" &&
        document.activeElement.classList.contains("noscroll")
      ) {
        if (["ArrowUp", "ArrowDown"].includes(event.code)) {
          event.preventDefault();
        }
      }
    });
  }, []);

  return {
    resetRegistrationForm,
    RegForm: () => (
      <>
        <RegForm
          ref={registrationFormData}
          handleRegistrationSubmit={handleRegistrationSubmit}
        />
      </>
    ),
  };
};

export { useRegistrationForm, RegForm };
